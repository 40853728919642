<template>
  <base-section id="how-security">
    <base-section-heading
      color="primary lighten-2"
      :title="$i18n.t('title')"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in fullCards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          icon: 'fas fa-qrcode',
          moreInfo: 'intervention-mode',
        },
        {
          icon: 'fas fa-file-invoice',
          moreInfo: 'safety',
        },
        {
          icon: 'fas fa-leaf',
          moreInfo: 'eco-friendly',
        },
      ],
    }),

    computed: {
      fullCards () {
        return this.cards.map((card, i) => {
          card.title = this.$i18n.t(`t${i}.title`)
          card.subtitle = this.$i18n.t(`t${i}.subtitle`)
          card.text = this.$i18n.t(`t${i}.text`)
          return card
        })
      },
    },

  }
</script>
<i18n>
{
	"en": {
		"t0": {
			"subtitle": "Maximum professionalism",
			"text": "Plant cleaners operate and are trained according to SGD Group guidelines. SGD personally takes care of verifying the effective sanitation and the presence of pathogens.",
			"title": "Intervention method"
		},
		"t1": {
			"subtitle": "Safety is guarantee",
			"text": "We follow the intervention protocol provided by SGD Group, to guarantee the highest quality and safety of the service.",
			"title": "Intervention protocol"
		},
		"t2": {
			"subtitle": "Care for the environment",
			"text": "We use products that respect the environment, without causing risks to the soil or aquatic ecosystem. Attention to the environment has always occupied an important position for SGD Group.",
			"title": "Eco-friendly solution"
		},
		"title": "How do we guarantee your safety?"
	},
	"it": {
		"t0": {
			"subtitle": "Trasparenza d'intervento",
			"text": "Grazie ai codici QR puoi tracciare la data e l'ora di sanificazione dei lettini e degli ombrelloni del tuo stabilimento in modo tale che i tuoi ospiti possano verificare l'avvenuta disinfezione.<br /> Non hai tempo di affiggere i codici QR e associarli? Ce ne occupiamo noi!",
      "title": "Tracciamento sanificazioni e avviamento"
		},
		"t1": {
			"subtitle": "Sicurezza garantita",
			"text": "Formiamo il tuo personale addetto alla sanificazione secondo il protocollo d'intervento SGD Group, da oltre 50 anni esperta in sanificazioni e disinfestazioni, che aderisce alle normative vigenti e agli standard di QUALITÀ UNI EN ISO 9001:2015 e UNI EN 16636:2015, AMBIENTE UNI EN ISO 14001:2015 e SICUREZZA UNI ISO 45001:2018.",
			"title": "Formazione e protocollo d'intervento"
		},
		"t2": {
			"subtitle": "Attenzione all’ambiente",
			"text": "Proponiamo l'utilizzo di prodotti che rispettano l'ambiente, senza rischi per l'ecosistema terreno ed acquatico. Il prodotto o i sanificanti da impiegare sono a libera scelta. Il consiglio è di utilizzare prodotti professionali a basso impatto ambientale.",
			"title": "Eco-friendly"
		},
		"title": "Come garantiamo la sicurezza"
	}
}
</i18n>
